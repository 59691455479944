var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuthenticated)?_c('div',{staticClass:"d-flex flex-column flex-root"},[_c('KTHeaderMobile'),(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-column-fluid page"},[(_vm.asideEnabled)?_c('KTAside'):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper",attrs:{"id":"kt_wrapper"}},[_c('KTHeader'),_c('div',{staticClass:"content d-flex flex-column flex-column-fluid",attrs:{"id":"kt_content"}},[_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('v-app',[(_vm.errors && _vm.errors.length > 0)?[_c('div',{staticClass:"btx-alert"},[_vm._l((_vm.errors),function(error,index){return [_c('v-snackbar',{key:("error-" + index),attrs:{"transition":"slide-y-transition","timeout":error.timeout,"color":"red lighten-1","elevation":"5","absolute":"","top":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',_vm._b({attrs:{"color":"white","small":""},on:{"click":function($event){error.model = false}}},'v-icon',attrs,false),[_vm._v("mdi-close")])]}}],null,true),model:{value:(error.model),callback:function ($$v) {_vm.$set(error, "model", $$v)},expression:"error.model"}},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_c('span',{staticClass:"ml-2",domProps:{"innerHTML":_vm._s(error.message)}})],1)]})],2)]:_vm._e(),(_vm.messages && _vm.messages.length > 0)?[_c('div',{staticClass:"btx-alert"},[_vm._l((_vm.messages),function(success,index){return [_c('v-snackbar',{key:("success-" + index),attrs:{"transition":"slide-y-transition","timeout":success.timeout,"color":"green","elevation":"5","absolute":"","top":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',_vm._b({attrs:{"color":"white","small":""},on:{"click":function($event){success.model = false}}},'v-icon',attrs,false),[_vm._v("mdi-close")])]}}],null,true),model:{value:(success.model),callback:function ($$v) {_vm.$set(success, "model", $$v)},expression:"success.model"}},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_c('span',{staticClass:"ml-2",domProps:{"innerHTML":_vm._s(success.message)}})],1)]})],2)]:_vm._e(),_c('v-container',{staticClass:"bg-white main-container px-0",attrs:{"fluid":""}},[_c('router-view'),(_vm.fdownloads.length)?_c('div',{staticClass:"zip-layout",style:(_vm.$vuetify.breakpoint.xl ? 'width: 400px' : 'width: 300px')},[_c('v-expansion-panels',{attrs:{"accordion":"","flat":"","tile":""},model:{value:(_vm.download_box),callback:function ($$v) {_vm.download_box=$$v},expression:"download_box"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('p',{staticClass:"h4"},[_vm._v(_vm._s(_vm.get_download_title()))])]),_c('v-expansion-panel-content',[_c('table',{staticClass:"download-list",attrs:{"width":"100%"}},_vm._l((_vm.fdownloads),function(row,index){return _c('tr',{key:index},[_c('td',{staticClass:"py-1 px-2",attrs:{"width":"15%","align":"left"}},[_c('v-tooltip',{key:("tooltip-file-" + index),attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue darken-4","disabled":!row.url},on:{"click":function($event){return _vm.download_file(row)}}},'v-icon',attrs,false),on),[_vm._v("mdi-download-multiple")])]}}],null,true)},[_c('span',[_vm._v(" Click here to download ")])])],1),_c('td',{staticClass:"py-1",attrs:{"width":"65%","align":"left"}},[(row.status)?_c('span',{staticClass:"font-level-2-bold"},[_vm._v(_vm._s(row.name)+" zipped")]):_c('span',{staticClass:"font-level-2-bold"},[_vm._v("Zipping "+_vm._s(row.name))])]),_c('td',{staticClass:"py-1 px-2",attrs:{"width":"20%","align":"center"}},[(row.status)?[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green"}},[_vm._v("mdi-check")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.delete_row(index)}}},[_vm._v("mdi-close")])]:_c('v-progress-circular',{attrs:{"indeterminate":"","size":20,"width":3,"color":"blue darken-4"}})],2)])}),0)])],1)],1)],1):_vm._e()],1)],2)],1)])],1)],1),_c('KTScrollTop'),(_vm.upload_dialog)?_c('upload-template',{attrs:{"dialog":_vm.upload_dialog},on:{"close":function($event){_vm.upload_dialog = false},"success":function($event){_vm.upload_dialog = false}}}):_vm._e(),(_vm.folder_upload_dialog)?_c('folder-upload-template',{attrs:{"dialog":_vm.folder_upload_dialog},on:{"close":function($event){_vm.folder_upload_dialog = false},"success":function($event){_vm.folder_upload_dialog = false}}}):_vm._e(),(_vm.folder_new_dialog)?_c('folder-new-template',{attrs:{"dialog":_vm.folder_new_dialog},on:{"close":function($event){_vm.folder_new_dialog = false},"success":function($event){_vm.folder_new_dialog = false}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }