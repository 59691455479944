<template>
	<div class="h-100">
		<ul class="menu-nav">
			<router-link
				:to="{ name: 'clients', query: { t: new Date().getTime() } }"
				v-slot="{ href, navigate, isActive, isExactActive }"
			>
				<li
					aria-haspopup="true"
					data-menu-toggle="hover"
					class="menu-item"
					:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
				>
					<a :href="href" class="menu-link" @click="navigate">
						<span class="menu-svg-icon mr-4">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/member.svg')" />
							<!--end::Svg Icon-->
						</span>
						<span class="menu-text">Clients</span>
					</a>
					<span class="menu-tooltips">Clients</span>
				</li>
			</router-link>
		</ul>
	</div>
</template>

<script>
export default {
	name: "KTMenu",
	methods: {
		hasActiveChildren(match) {
			return this.$route["path"].indexOf(match) !== -1;
		},
	},
};
</script>
