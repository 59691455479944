<template>
	<v-menu
		ref="datepicker"
		v-model="datepicker"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="auto"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				:id="id"
				:label="label"
				:loading="loading"
				:disabled="disabled"
				:rules="rules"
				readonly
				outlined
				class="pt-0"
				:class="{ 'mt-3': !topDense, ...contentClass }"
				v-model="dateFormatted"
				:placeholder="btxPlaceholder"
				hide-details
				@click:clear="$emit('click:clear', true)"
				:clearable="clearable"
				:prepend-inner-icon="hidePrefixIcon ? '' : 'mdi-calendar'"
				v-bind="attrs"
				v-on:blur="date = parseDate(dateFormatted)"
				v-on="on"
			></v-text-field>
		</template>
		<v-date-picker
			v-model="date"
			no-title
			:min="minDate"
			:max="maxDate"
			:disabled="disabled"
			:readonly="readonly"
			@input="datepicker = false"
		></v-date-picker>
	</v-menu>
</template>

<script>
export default {
	name: "date-picker",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [Date, String],
			default: null,
		},
		hidePrefixIcon: {
			type: Boolean,
			default: false,
		},
		minDate: {
			type: [Date, String],
			default: null,
		},
		maxDate: {
			type: [Date, String],
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		placeholder: {
			type: String,
			default: "Date",
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		contentClass: {
			type: Object,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		topDense: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			date: null,
			datepicker: false,
			dateFormatted: null,
		};
	},
	watch: {
		minDate() {
			this.date = null;
		},
		maxDate() {
			this.date = null;
		},
		date(new_value, old_value) {
			if (new_value != old_value) {
				this.emitValue();
			}
		},
		value() {
			this.date = this.value;
		},
	},
	methods: {
		emitValue() {
			this.dateFormatted = this.formatDate(this.date);
			this.$emit("input", this.date);
			this.$emit("change", this.date);
		},
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		parseDate(date) {
			if (!date) return null;
			const [day, month, year] = date.split("/");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		},
	},
	computed: {
		btxPlaceholder() {
			return this.placeholder + " [DD/MM/YYYY]";
		},
	},
	mounted() {
		this.date = this.value;
		this.$nextTick(() => {
			this.emitValue();
		});
	},
};
</script>
