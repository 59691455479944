<template>
	<v-menu
		content-class="members-list"
		offset-y
		:close-on-content-click="false"
		:rounded="false"
		tile
		v-model="menu"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				hide-details
				color="blue darken-4"
				class="search-field mt-0 pt-0"
				placeholder="Search..."
				prepend-inner-icon="mdi-magnify"
				clearable
				outlined
				v-bind="attrs"
				v-on="on"
				v-model="search"
				:loading="loading || btxLoading"
				:disabled="disabled"
				v-on:keyup="get_members(true)"
				v-on:keyup.enter="get_members(true)"
				v-on:click:clear="get_members(false)"
			></v-text-field>
		</template>
		<div style="max-height: calc(100vh - 400px); overflow: auto" :class="{ 'dense-list': dense }">
			<v-list class="py-0" two-line>
				<v-list-item-group
					v-model="selected_members"
					v-if="members.length"
					multiple
					color="blue darken-4"
				>
					<v-list-item
						v-for="(item, index) in members"
						:key="index"
						:value="item.id"
						:disabled="defaultMember.id == item.id"
						:class="{
							'border-bottom-light-grey': members[index + 1],
						}"
						v-on:click="add_member(item)"
					>
						<v-list-item-avatar>
							<img :src="item.profile ? item.profile : $assetURL('media/users/blank.png')" />
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title v-html="item.display_name"></v-list-item-title>
							<v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-btn
								v-if="defaultMember.id == item.id"
								color="blue darken-4 white--text"
								class="text-capitalize"
								text
							>
								Owner
							</v-btn>
						</v-list-item-action>
					</v-list-item>
				</v-list-item-group>
				<v-list-item v-else>
					<v-list-item-avatar>
						<img :src="$assetURL('media/users/blank.png')" />
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>Sorry ! No User Found.</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</div>
	</v-menu>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import { find, findIndex } from "lodash";

export default {
	name: "search-members",
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
		dense: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		btxLoading: {
			type: Boolean,
			default: false,
		},
		defaultMember: {
			type: Object,
			default() {
				return {
					id: 0,
					permission: null,
					title: null,
					first_name: null,
					last_name: null,
					phone_number: null,
					display_name: null,
					email: null,
					profile: null,
				};
			},
		},
	},
	data() {
		return {
			members: [],
			search: null,
			menu: false,
			loading: false,
			raw_members: [],
			selected_members: [],
			timeout: null,
			timeoutLimit: 500,
		};
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(param) {
				this.raw_members = param;
				this.$nextTick(() => {
					this.init_value(param);
				});
			},
		},
	},
	methods: {
		add_member(member) {
			const index = findIndex(this.raw_members, { id: member.id });
			if (index < 0) {
				member.permission = "editor";
				this.raw_members.push(member);
			} else {
				this.raw_members.splice(index, 1);
			}
			this.$nextTick(() => {
				this.emit_value();
			});
		},
		init_value(response) {
			this.selected_members = [];
			for (let i = 0; i < response.length; i++) {
				if (find(this.raw_members, { id: response[i].id })) {
					this.selected_members.push(response[i].id);
				}
			}
		},
		get_members(menu) {
			const _this = this;
			_this.loading = true;
			_this.menu = false;
			clearTimeout(_this.timeout);
			_this.$nextTick(() => {
				_this.timeout = setTimeout(function () {
					_this.$store
						.dispatch(QUERY, {
							url: "list-users",
							data: { search: _this.search },
						})
						.then((response) => {
							_this.members = response;
							_this.$nextTick(() => {
								_this.menu = menu;
								_this.init_value(response);
							});
						})
						.catch((error) => {
							console.log({ error });
						})
						.finally(() => {
							_this.loading = false;
						});
				}, _this.timeoutLimit);
			});
		},
		emit_value() {
			this.$emit("input", this.raw_members);
			this.$emit("change", this.raw_members);
		},
	},
	mounted() {
		this.get_members(false);
	},
};
</script>
